import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    items: [],
    showShoppingCart: false,
    currency: {
      separator: ",",
      symbol: "",
      pattern: "!#",
      precision: 0,
      decimal: "."
    },
    selected: null,
    showProductOptionsModal: false
  },
  actions,
  getters,
  mutations
};
