<template>
  <footer v-if="branded || showFooter" class="bg-white">
    <div class="container mx-auto px-4 py-6 text-center text-gray-900">
      <div v-if="showFooter">
        <p>
          {{ description }}
        </p>
        <div v-if="content" class="py-4">
          <markup-content :content="content" />
        </div>
        <router-link v-if="pivacyPolicy" to="privacy-policy" class="mt-3">
          {{ $t("privacy_policy") }}
        </router-link>
      </div>
      <p v-if="branded">
        {{ $t("made_with") }}
        <strong>
          <a href="https://getbiew.com/">Biew</a>
        </strong>
      </p>
    </div>
  </footer>
</template>

<script>
import MarkupContent from "@/components/MarkupContent";

const moduleName = "config";

export default {
  name: "TheFooter",

  components: {
    MarkupContent
  },

  props: {
    branded: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    config() {
      return this.$store.state[moduleName].footer;
    },
    description() {
      return this.config.description;
    },
    content() {
      return this.config.content;
    },
    pivacyPolicy() {
      return this.$store.state[moduleName].privacyPolicy;
    },
    showFooter() {
      return this.config.show;
    }
  }
};
</script>
