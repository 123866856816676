<template>
  <div class="p-6 text-center bg-white">
    <div class="font-black text-lg mb-4">{{ $t("thank_you") }}!</div>
    <div class="mb-4">{{ $t("your_order_has_been_sent") }}!</div>
    <div class="mt-2 text-4xl">🎉</div>
    <button
      @click="$emit('close')"
      type="button"
      class="mt-4 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
    >
      {{ $t("close") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CheckoutConfirmed"
};
</script>
